import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { UALProvider } from "ual-reactjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import { Wombat } from "ual-wombat";

import * as serviceWorker from "./serviceWorker";

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";

import { showDebugData } from "helpers/env";
import { reducers } from "./redux/reducers";
import { sagas } from "./redux/sagas";

import App from "containers/App";

import {
  WAX_CHAIN_ID,
  WAX_RPC_ENDPOINTS_HOST,
  WAX_RPC_ENDPOINTS_PROTOCOL
} from "./constants";

import "styles/index.scss";

const saga = createSagaMiddleware();

let middleware = [];

if (process.env.NODE_ENV === "development") {
  // middleware = [...middleware, saga, logger];
  middleware = [...middleware, saga];
} else {
  middleware = [...middleware, saga];
}

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

saga.run(sagas);

showDebugData();

const chain = {
  chainId: WAX_CHAIN_ID,
  rpcEndpoints: [{
    protocol: WAX_RPC_ENDPOINTS_PROTOCOL,
    host: WAX_RPC_ENDPOINTS_HOST,
    port: ''
  }]
};

const wax = new Wax([chain], { appName: 'Collect' });
const anchor = new Anchor([chain], { appName: 'Collect' });
const wombat = new Wombat([chain], { appName: 'Collect' });

const authenticators = process.env.WAX_CHAIN === 'mainnet'
    ? [wombat, wax, anchor]
    : [anchor];

ReactDOM.render(
  // <React.StrictMode>
    <UALProvider chains={[chain]} authenticators={authenticators} appName={'Collect'}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </UALProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
