import Image from "../../assets/images/pic1.webp";
import Atari from "../../assets/images/authors/rsz_atari.webp";
import AlienWorlds from "../../assets/images/authors/rsz_1alienworlds.webp";
import BitcoinOrigin from "../../assets/images/authors/rsz_bitcoinorign.webp";
import CrptoMonkey from "../../assets/images/authors/rsz_crptomonkeys.webp";
import Darkcountry from "../../assets/images/authors/rsz_darkcountry.webp";
import GpkTopps from "../../assets/images/authors/rsz_gpktopps.webp";
import Hodlgod from "../../assets/images/authors/rsz_hodlgod.webp";
import KogsOfficial from "../../assets/images/authors/rsz_kogsofficial.webp";
import Monsterr2 from "../../assets/images/authors/rsz_monstersr2.webp";
import Mutantsr2 from "../../assets/images/authors/rsz_mutantsr2.webp";
import StfCapcom from "../../assets/images/authors/rsz_stfcapcom.webp";
import TribalBooks from "../../assets/images/authors/rsz_tribalbooks.webp";
import UltraHorrors from "../../assets/images/authors/rsz_ultrahorrors.webp";
import Upliftart from "../../assets/images/authors/rsz_upliftart.webp";
import Deadmau from "../../assets/images/authors/rsz_deadmau5.webp";
import Heroes from "../../assets/images/authors/heroes.webp";
import Nimoy from "../../assets/images/authors/rsz_nimoy.webp";
import Godzilla from "../../assets/images/authors/rsz_godzilla.webp";
import CartombsR2 from "../../assets/images/authors/rsz_cartombsr2.webp";
import Exitlimbo from "../../assets/images/authors/rsz_exitlimbowax.webp";
import Cryptomoonie from "../../assets/images/authors/rsz_cryptomonies.webp";
import DarkPinup from "../../assets/images/authors/rsz_darkpinup.webp";
import Writer from "../../assets/images/authors/rsz_writer.webp";
import AlpacaWorlds from "../../assets/images/authors/rsz_alpaca.webp";
import Starcardscpt from "../../assets/images/authors/rsz_starcardscpt.webp";
import Darkgalaxies from "../../assets/images/authors/darkgalaxies.webp";
import Godsnlegends from "../../assets/images/authors/godsnlegends.webp";
import Coindodocard from "../../assets/images/authors/coindodocard.webp";
import GraffitiKings from "../../assets/images/authors/graffiti_kings.webp";

import BonusRacoons100 from "../../assets/images/puzzle-prizes/10_000_Racoons.webp";
import BonusRacoons200 from "../../assets/images/puzzle-prizes/2000_Racoons.webp";
import BonusRacoons300 from "../../assets/images/puzzle-prizes/300_Racoons.webp";
import BonusRacoons500 from "../../assets/images/puzzle-prizes/5000_Racoons.webp";
import BonusRacoons1000 from "../../assets/images/puzzle-prizes/10_000_Racoons.webp";
import BonusRacoons2000 from "../../assets/images/puzzle-prizes/2000_Racoons.webp";
import BonusRacoons5000 from "../../assets/images/puzzle-prizes/5000_Racoons.webp";
import BonusRacoons10000 from "../../assets/images/puzzle-prizes/10_000_Racoons.webp";
import BonusRacoons25000 from "../../assets/images/puzzle-prizes/25_000_Racoons.webp";

import Wax80 from "../../assets/images/puzzle-prizes/80_WAX.webp";
import Wax20 from "../../assets/images/puzzle-prizes/20_WAX.webp";
import Wax300 from "../../assets/images/puzzle-prizes/300_WAX.webp";
import Wax600 from "../../assets/images/puzzle-prizes/600_WAX.webp";
import Wax10000 from "../../assets/images/puzzle-prizes/10000_WAX.webp";
import RacoonCoins100 from "../../assets/images/puzzle-prizes/100_Racoon_coins.webp";
import RacoonCoins300 from "../../assets/images/puzzle-prizes/300_Racoon_coins.webp";

import AuctionSpot1 from "../../assets/images/auctionSpots/spot1.webp";
import AuctionSpot2 from "../../assets/images/auctionSpots/spot2.webp";
import AuctionSpot3 from "../../assets/images/auctionSpots/spot3.webp";
import AuctionSpot4 from "../../assets/images/auctionSpots/spot4.webp";
import AuctionSpot5 from "../../assets/images/auctionSpots/spot5.webp";
import AuctionSpot6 from "../../assets/images/auctionSpots/spot6.webp";
import AuctionSpot7 from "../../assets/images/auctionSpots/spot7.webp";
import AuctionSpot8 from "../../assets/images/auctionSpots/spot8.webp";
import AuctionSpot9 from "../../assets/images/auctionSpots/spot9.webp";
import AuctionSpot10 from "../../assets/images/auctionSpots/spot10.webp";
import AuctionSpot11 from "../../assets/images/auctionSpots/spot11.webp";
import AuctionSpot12 from "../../assets/images/auctionSpots/spot12.webp";
import AuctionSpot13 from "../../assets/images/auctionSpots/spot13.webp";
import AuctionSpot14 from "../../assets/images/auctionSpots/spot14.webp";
import AuctionSpot15 from "../../assets/images/auctionSpots/spot15.webp";
import AuctionSpot16 from "../../assets/images/auctionSpots/spot16.webp";
import AuctionSpot17 from "../../assets/images/auctionSpots/spot17.webp";
import AuctionSpot18 from "../../assets/images/auctionSpots/spot18.webp";
import AuctionSpot19 from "../../assets/images/auctionSpots/spot19.webp";
import AuctionSpot20 from "../../assets/images/auctionSpots/spot20.webp";
import AuctionSpot21 from "../../assets/images/auctionSpots/spot21.webp";
import AuctionSpot22 from "../../assets/images/auctionSpots/spot22.webp";
import AuctionSpot23 from "../../assets/images/auctionSpots/spot23.webp";
import AuctionSpot24 from "../../assets/images/auctionSpots/spot24.webp";
import AuctionSpot25 from "../../assets/images/auctionSpots/spot25.webp";

import BasicBooster from "../../assets/images/boosters/basic_booster.webp";
import CommonBooster from "../../assets/images/boosters/common_booster.webp";
import UncommonBooster from "../../assets/images/boosters/uncommon_booster.webp";
import RareBooster from "../../assets/images/boosters/rare_booster.webp";
import EpicBooster from "../../assets/images/boosters/epic_booster.webp";
import LegendaryBooster from "../../assets/images/boosters/legendary_booster.webp";
import MythicBooster from "../../assets/images/boosters/mythic_booster.webp";

import PuzzleGameImage1 from "../../assets/images/puzzleGame/dark_country_lands_test.webp";
import PuzzleGameImage2 from "../../assets/images/puzzleGame/dark_country_p2e.webp";
import PuzzleGameImage3 from "../../assets/images/puzzleGame/racoon_boosters.webp";

import {
  BLOCK_SIZE,
  SPOT_1_TEMPLATE_ID,
  SPOT_2_TEMPLATE_ID,
  SPOT_3_TEMPLATE_ID,
  SPOT_4_TEMPLATE_ID,
  SPOT_5_TEMPLATE_ID,
  SPOT_6_TEMPLATE_ID,
  SPOT_7_TEMPLATE_ID,
  SPOT_8_TEMPLATE_ID,
  SPOT_9_TEMPLATE_ID,
  SPOT_10_TEMPLATE_ID,
  SPOT_11_TEMPLATE_ID,
  SPOT_12_TEMPLATE_ID,
  SPOT_13_TEMPLATE_ID,
  SPOT_14_TEMPLATE_ID,
  SPOT_15_TEMPLATE_ID,
  SPOT_16_TEMPLATE_ID,
  SPOT_17_TEMPLATE_ID,
  SPOT_18_TEMPLATE_ID,
  SPOT_19_TEMPLATE_ID,
  SPOT_20_TEMPLATE_ID,
  SPOT_21_TEMPLATE_ID,
  SPOT_22_TEMPLATE_ID,
  SPOT_23_TEMPLATE_ID,
  SPOT_24_TEMPLATE_ID,
  SPOT_25_TEMPLATE_ID,
  BASIC_BOOSTER_TEMPLATE_ID,
  COMMON_BOOSTER_TEMPLATE_ID,
  UNCOMMON_BOOSTER_TEMPLATE_ID,
  RARE_BOOSTER_TEMPLATE_ID,
  EPIC_BOOSTER_TEMPLATE_ID,
  LEGENDARY_BOOSTER_TEMPLATE_ID,
  MYTHIC_BOOSTER_TEMPLATE_ID
} from "../../constants/gallery.constants";
import { PUZZLE_GAME_IMAGES } from "../../constants/puzzleGame.constants";

export const getAuctionSpotImageByTemplateId = (templateId) => {
  if (!templateId)
    return '';

  switch (templateId.toString()) {
    case SPOT_1_TEMPLATE_ID:
      return AuctionSpot1;

    case SPOT_2_TEMPLATE_ID:
      return AuctionSpot2;

    case SPOT_3_TEMPLATE_ID:
      return AuctionSpot3;

    case SPOT_4_TEMPLATE_ID:
      return AuctionSpot4;

    case SPOT_5_TEMPLATE_ID:
      return AuctionSpot5;

    case SPOT_6_TEMPLATE_ID:
      return AuctionSpot6;

    case SPOT_7_TEMPLATE_ID:
      return AuctionSpot7;

    case SPOT_8_TEMPLATE_ID:
      return AuctionSpot8;

    case SPOT_9_TEMPLATE_ID:
      return AuctionSpot9;

    case SPOT_10_TEMPLATE_ID:
      return AuctionSpot10;

    case SPOT_11_TEMPLATE_ID:
      return AuctionSpot11;

    case SPOT_12_TEMPLATE_ID:
      return AuctionSpot12;

    case SPOT_13_TEMPLATE_ID:
      return AuctionSpot13;

    case SPOT_14_TEMPLATE_ID:
      return AuctionSpot14;

    case SPOT_15_TEMPLATE_ID:
      return AuctionSpot15;

    case SPOT_16_TEMPLATE_ID:
      return AuctionSpot16;

    case SPOT_17_TEMPLATE_ID:
      return AuctionSpot17;

    case SPOT_18_TEMPLATE_ID:
      return AuctionSpot18;

    case SPOT_19_TEMPLATE_ID:
      return AuctionSpot19;

    case SPOT_20_TEMPLATE_ID:
      return AuctionSpot20;

    case SPOT_21_TEMPLATE_ID:
      return AuctionSpot21;

    case SPOT_22_TEMPLATE_ID:
      return AuctionSpot22;

    case SPOT_23_TEMPLATE_ID:
      return AuctionSpot23;

    case SPOT_24_TEMPLATE_ID:
      return AuctionSpot24;

    case SPOT_25_TEMPLATE_ID:
      return AuctionSpot25;

    default:
      return '';
  }
};

export const getAuthorImage = (author) => {
  switch (author) {
    case 'atari':
      return Atari;

    case 'Godzilla':
      return Godzilla;

    case 'bitcoinorign':
      return BitcoinOrigin;

    case 'crptomonkeys':
      return CrptoMonkey;

    case 'darkcountry':
      return Darkcountry;

    case 'deadmau5':
      return Deadmau;

    case 'gpk.topps':
      return GpkTopps;

    case 'hodlgod':
      return Hodlgod;

    case 'kogsofficial':
      return KogsOfficial;

    case 'monsters.r2':
      return Monsterr2;

    case 'mutants.r2':
      return Mutantsr2;

    case 'heroes':
      return Heroes;

    case 'Blockchain Heroes':
      return Heroes;

    case 'stf.capcom':
      return StfCapcom;

    case 'tribalbooks':
      return TribalBooks;

    case 'ultrahorrors':
      return UltraHorrors;

    case 'alien.worlds':
      return AlienWorlds;

    case 'upliftart':
      return Upliftart;

    case 'nimoy':
      return Nimoy;

    case 'cartombs.r2':
      return CartombsR2;

    case 'exitlimbowax':
      return Exitlimbo;

    case 'cryptomoonie':
      return Cryptomoonie;

    case 'darkpinup':
      return DarkPinup;

    case 'writer':
      return Writer;

    case 'alpacaworlds':
      return AlpacaWorlds;

    case 'starcardscpt':
      return Starcardscpt;

    case 'darkgalaxies':
      return Darkgalaxies;

    case 'godsnlegends':
      return Godsnlegends;

    case 'coindodocard':
      return Coindodocard;

    case 'graffk1ngsuk':
      return GraffitiKings;

    default:
      return Image;
  }
};

export const getBonusRacoonsImageByValue = (value) => {
  switch (value) {
    case '100':
      return BonusRacoons100;

    case '200':
      return BonusRacoons200;

    case '300':
      return BonusRacoons300;

    case '500':
      return BonusRacoons500;

    case '1000':
      return BonusRacoons1000;

    case '2000':
      return BonusRacoons2000;

    case '5000':
      return BonusRacoons5000;

    case '10000':
      return BonusRacoons10000;

    case '25000':
      return BonusRacoons25000;

    case 'WAX 20':
      return Wax20;

    case 'WAX 80':
      return Wax80;

    case 'WAX 300':
      return Wax300;

    case 'WAX 600':
      return Wax600;

    case 'WAX 10000':
      return Wax10000;

    case 'Coins 100':
      return RacoonCoins100;

    case 'Coins 300':
      return RacoonCoins300;

    default:
      return BonusRacoons100;
  }
};

export const getBoosterImage = (templateId) => {
  if (!templateId)
    return "";

  switch (templateId.toString()) {
    case BASIC_BOOSTER_TEMPLATE_ID:
      return BasicBooster;

    case COMMON_BOOSTER_TEMPLATE_ID:
      return CommonBooster;

    case UNCOMMON_BOOSTER_TEMPLATE_ID:
      return UncommonBooster;

    case RARE_BOOSTER_TEMPLATE_ID:
      return RareBooster;

    case EPIC_BOOSTER_TEMPLATE_ID:
      return EpicBooster;

    case LEGENDARY_BOOSTER_TEMPLATE_ID:
      return LegendaryBooster;

    case MYTHIC_BOOSTER_TEMPLATE_ID:
      return MythicBooster;

    default:
      return BasicBooster;
  }
};

export const getRandomPuzzleGameImage = () => {
  const images = [...PUZZLE_GAME_IMAGES, PuzzleGameImage1, PuzzleGameImage2, PuzzleGameImage3];

  return images[Math.floor(Math.random() * images.length)];
};

export const checkIfImageIsGamePromo = (image) => {
  return [PuzzleGameImage1, PuzzleGameImage2, PuzzleGameImage3].includes(image);
};

export const getProjectLinkByImage = (image) => {
  switch (image) {
    case PuzzleGameImage1:
      return 'https://chronicles.darkcountry.io/lands/dc-wax-lands-testnet-launch/';

    case PuzzleGameImage2:
      return 'https://chronicles.darkcountry.io/dark-country-play-2-earn-universe/';

    case PuzzleGameImage3:
      return 'https://wax.atomichub.io/market?collection_name=collectwhale&order=asc&schema_name=booster&sort=price&symbol=WAX';

    default:
      return 'https://collect.social/gallery';
  }
};

export const countGalleryImageSize = (image) => {
  let imageWidth, imageHeight;
  let imageWidthPercent, imageHeightPercent;
  let x, y;

  if (image.naturalWidth === image.naturalHeight) {
    imageWidth = BLOCK_SIZE;
    imageHeight = BLOCK_SIZE;

    x = 0;
    y = 0;
  }

  if (image.naturalWidth > image.naturalHeight) {
    imageWidth = BLOCK_SIZE;

    imageHeightPercent = (image.naturalHeight * 100) / image.naturalWidth;
    imageHeight = (BLOCK_SIZE / 100) * imageHeightPercent;

    y = Math.round((BLOCK_SIZE - imageHeight) / 2);
  }

  if (image.naturalWidth < image.naturalHeight) {
    imageHeight = BLOCK_SIZE;

    imageWidthPercent = (image.naturalWidth * 100) / image.naturalHeight;
    imageWidth = (BLOCK_SIZE / 100) * imageWidthPercent;

    x = Math.round((BLOCK_SIZE - imageWidth) / 2);
  }

  return { width: imageWidth, height: imageHeight, x, y };
};

